import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import url from 'url';

import I18n from 'common/i18n';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import Button, { SIZES, VARIANTS } from 'common/components/Button';

import Container from '../Container';
import { Options } from '../../types';

import './mfa.scss';

const classNameScope = 'frontend--authentication--components--mfa';

const QR_CODE_SIZE = 200;

class AssociateMfa extends Component<Partial<Options>> {
  /**
   * For some awful reason, Google Authenticator on iOS can't scan the code if it has "algorithm", "digits", and "period" query params
   * Removing these, it will scan the code just fine (and it will still work?!?!)
   * This is sort of an awful hack and if auth0 ever decides to change their algorithm and forces everybody to re-associate their devices,
   * this may or may not break, but we can deal with that when the time comes...
   * @param {string} mfaBarcodeUri MFA URI to mangle
   */
  static mangleBarcodeUri(mfaBarcodeUri: string) {
    const parsedUri = url.parse(mfaBarcodeUri, true); // true == parseQueryString

    // these three query params are what break Google Authenticator on iOS
    delete parsedUri.query.algorithm;
    delete parsedUri.query.digits;
    delete parsedUri.query.period;

    // also have to get rid of this from the object because the url library prefers it over the query object
    // @ts-expect-error TS(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    delete parsedUri.search;

    return url.format(parsedUri);
  }

  render() {
    const { flashes, mfaBarcodeUri, validateOtpPath, cancelMfaPath } = this.props;

    return (
      <Container flashes={flashes} header={I18n.t('account.common.mfa.header')}>
        <div className={`${classNameScope}--instructions`}>
          <div className={`${classNameScope}--text`}>
            <p>{I18n.t('account.common.mfa.setup_app_instructions')}</p>
            <a href="https://support.socrata.com/hc/en-us/articles/360016843234-Two-Factor-Authentication">
              {I18n.t('account.common.mfa.learn_more')}
            </a>
          </div>
          <div>
            <QRCode value={AssociateMfa.mangleBarcodeUri(mfaBarcodeUri!)} size={QR_CODE_SIZE} />
          </div>
        </div>
        <Form action={validateOtpPath}>
          <Input
            label={I18n.t('account.common.mfa.enter_code')}
            autoFocus
            placeholder={I18n.t('account.common.mfa.enter_code')}
            name="otp"
            type="number"
          />
          <div className={`${classNameScope}--button-container`}>
            <Form action={cancelMfaPath}>
              <Button size={SIZES.LARGE} type="submit">
                {I18n.t('account.common.mfa.cancel')}
              </Button>
            </Form>
            <Button variant={VARIANTS.PRIMARY} dark size={SIZES.LARGE} type="submit">
              {I18n.t('account.common.mfa.submit')}
            </Button>
          </div>
        </Form>
      </Container>
    );
  }
}

export default AssociateMfa;
