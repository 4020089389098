import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import I18n from 'common/i18n';

import { isValidEmail, findConnection } from './Util';
import { SsoConnection } from 'authentication/types';

const emailInputName = 'signup[email]';
const passwordInputName = 'signup[password]';
const passwordConfirmInputName = 'signup[passwordConfirm]';

interface State {
  inputs: any;
  passwordMinLength: number;
  passwordMaxLength: number;
  ssoConnections: SsoConnection[];
  platformAdminConnection: SsoConnection;
}

export const validateEmail = (state: {
  inputs: any;
  ssoConnections: SsoConnection[];
  platformAdminConnection: SsoConnection;
}) => {
  const { inputs, ssoConnections, platformAdminConnection } = state;
  const { value } = inputs[emailInputName];

  const email = {
    ...inputs[emailInputName],
    valid: true,
    errorMessage: ''
  };

  const strictValidation = true;
  if (!isEmpty(value) && !isValidEmail(value, strictValidation)) {
    email.valid = false;
    email.errorMessage = I18n.t('core.validation.email');
  }

  // check if the email entered will go through SSO or not on this domain
  const shouldGoThroughSso = findConnection(value, ssoConnections, null) != null;

  // enabled/disabled all the inputs depending on if it will go through SSO (to prevent user from entering any more info)
  const inputsCloned = mapValues(inputs, (input, key) => ({
    ...input,
    disabled: key !== emailInputName && shouldGoThroughSso
  }));

  inputsCloned[emailInputName] = email;
  return {
    ...state,
    inputs: inputsCloned,
    shouldGoThroughSso
  };
};

export const validatePassword = (state: State) => {
  const { inputs, passwordMinLength, passwordMaxLength } = state;
  const { value } = inputs[passwordInputName];

  const password = {
    ...inputs[passwordInputName],
    valid: true,
    errorMessage: ''
  };

  if (!isEmpty(value)) {
    if (value.length < passwordMinLength) {
      password.valid = false;
      password.errorMessage = I18n.t('account.common.validation.password_short', {
        count: passwordMinLength
      });
    } else if (value.length > passwordMaxLength) {
      password.valid = false;
      password.errorMessage = I18n.t('account.common.validation.password_long', { count: passwordMaxLength });
    }
  }

  return { ...state, inputs: { ...inputs, [passwordInputName]: password } };
};

export const validatePasswordConfirm = (state: State) => {
  const { inputs, passwordMinLength, passwordMaxLength } = state;
  const { value } = inputs[passwordConfirmInputName];
  const passwordValue = inputs[passwordInputName].value;

  const passwordConfirm = {
    ...inputs[passwordConfirmInputName],
    valid: true,
    errorMessage: ''
  };

  if (!isEmpty(value)) {
    if (value.length < passwordMinLength) {
      passwordConfirm.valid = false;
      passwordConfirm.errorMessage = I18n.t('account.common.validation.password_short', {
        count: passwordMinLength
      });
    } else if (value.length > passwordMaxLength) {
      passwordConfirm.valid = false;
      passwordConfirm.errorMessage = I18n.t('account.common.validation.password_long', {
        count: passwordMaxLength
      });
    } else if (value !== passwordValue) {
      passwordConfirm.valid = false;
      passwordConfirm.errorMessage = I18n.t('account.common.validation.mismatch');
    }
  }

  return { ...state, inputs: { ...inputs, [passwordConfirmInputName]: passwordConfirm } };
};
