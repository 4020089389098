import React, { Component } from 'react';
import { Link } from 'react-router';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import url from 'url';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import I18n from 'common/i18n';
import {
  createDefaultInputsState,
  createStoreWithFormReducer
} from 'common/components/Forms/redux/FormReduxUtils';

import SignUpForm from './SignUpForm/SignUpForm';

import { renderAlerts } from '../Util';
import { Options as OptionsType } from '../types';
import { validateEmail, validatePassword, validatePasswordConfirm } from '../SignUpFormValidations';

import './signup.scss';

const classNameScope = 'frontend--authentication--components--signup';

interface Props {
  options: OptionsType;
}
class SignUp extends Component<Props> {
  store: ReturnType<typeof createStore>;

  // creates the "inputs" part of the state
  static getDefaultInputsState = (props: Props, parsedUrl: url.UrlWithParsedQuery) => {
    const { params } = props.options;

    // if an email comes in from the query string, we want to use that,
    // otherwise grab it from params, otherwise empty
    const email = get(parsedUrl, 'query.email', get(params, 'email', ''));
    const screenName = get(params, 'screenName', '');

    return createDefaultInputsState(
      {
        // the "name" value for these properties is important for rails to be able to pass the values through to core
        'signup[email]': {
          name: 'signup[email]',
          valid: false,
          label: I18n.t('account.common.form.email'),
          value: isNull(email) ? '' : email,
          required: true,
          type: 'email'
        },
        'signup[screenName]': {
          name: 'signup[screenName]',
          type: 'not a checkbox',
          valid: false,
          label: I18n.t('account.common.form.display_name'),
          value: isNull(screenName) ? '' : screenName,
          required: true
        },
        'signup[password]': {
          name: 'signup[password]',
          valid: false,
          label: I18n.t('account.common.form.password'),
          required: true,
          type: 'password'
        },
        'signup[passwordConfirm]': {
          name: 'signup[passwordConfirm]',
          valid: false,
          label: I18n.t('account.common.form.confirm_password'),
          required: true,
          type: 'password'
        }
      },
      true // includeRecaptcha
    );
  };

  constructor(props: Props) {
    super(props);

    const { passwordMaxLength, passwordMinLength, ssoConnections, platformAdminConnection } =
      this.props.options;

    const parsedUrl = url.parse(window.location.href, true);
    const urlAuthToken = get(parsedUrl, 'query.auth_token', '');
    const redirectUrl = get(parsedUrl, 'query.redirect_url', '');
    const initiatedBy = get(parsedUrl, 'query.initiated_by', '');

    const defaultState = {
      formSubmitted: false,
      urlAuthToken,
      redirectUrl,
      initiatedBy,
      passwordMaxLength,
      passwordMinLength,
      ssoConnections,
      platformAdminConnection,
      shouldGoThroughSso: false,
      inputs: SignUp.getDefaultInputsState(props, parsedUrl)
    };

    const validations = {
      'signup[email]': validateEmail,
      'signup[password]': validatePassword,
      'signup[passwordConfirm]': validatePasswordConfirm
    };

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ 'signup[email]': (state: { inp... Remove this comment to see the full error message
    this.store = createStoreWithFormReducer(defaultState, validations, 'SignUpForm');
  }

  renderDisclaimer = () => {
    const { signUpDisclaimer } = this.props.options;

    if (isEmpty(signUpDisclaimer)) {
      return null;
    }

    return <div className={`${classNameScope}--disclaimer`}>{signUpDisclaimer}</div>;
  };

  render() {
    const { options } = this.props;
    const { loginPath } = options;

    const flashes = get(this.props, 'options.flashes', null);

    return (
      <div className={`container ${classNameScope}--container`}>
        {renderAlerts(flashes)}

        <h3>{I18n.t('screens.sign_up.headline', { site: options.companyName })}</h3>

        <div className={`${classNameScope}--divider`}></div>

        <h4
          className={`${classNameScope}--create-new-id`}
          dangerouslySetInnerHTML={{
            __html: I18n.t('screens.sign_up.prompt_html_tyler')
          }}
        />

        <h5
          className={`${classNameScope}--socrata-powered`}
          dangerouslySetInnerHTML={{
            __html: I18n.t('screens.sign_in.tips_html_tyler')
          }}
        />

        {this.renderDisclaimer()}

        <Provider store={this.store}>
          <SignUpForm options={options} />
        </Provider>

        <div className={`${classNameScope}--go-to-signin`}>
          {I18n.t('screens.sign_up.already_have_account')}{' '}
          <Link className={`${classNameScope}--signin-link`} to={loginPath}>
            {I18n.t('screens.sign_in.form.sign_in_button')}
          </Link>
        </div>
      </div>
    );
  }
}

export default SignUp;
