import React, { Component } from 'react';

import { renderAlerts } from '../../Util';
import { Alert } from '../../types';

import './styles.scss';

const classNameScope = 'frontend--authentication--components--container';

interface ContainerProps {
  flashes?: Alert[];
  header: string;
  children: any;
}

class Container extends Component<ContainerProps> {
  render() {
    const { children, flashes, header } = this.props;

    // @ts-expect-error TS(2345) FIXME: Argument of type 'Alert[] | undefined' is not assi... Remove this comment to see the full error message
    const renderedAlertJSX = renderAlerts(flashes);
    return (
      <div className={`${classNameScope}--container`}>
        {header && (
          <div className={`${classNameScope}--header-container`}>
            <h2 className={`h2 ${classNameScope}--header`}>{header}</h2>
          </div>
        )}
        {renderedAlertJSX}
        <div className={`${classNameScope}--inner-container`}>{children}</div>
      </div>
    );
  }
}

export default Container;
